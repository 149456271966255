<template>
    <div class="button-load-more">
        <button
            type="button"
            class="button-load-more_btn"
            :class="loading || disabled ? '' : 'hover:border-site-primary'"
            :disabled="loading || disabled"
            @click="() => handler()"
        >
            <span v-show="!loading">{{ $lang.components.btnSeeMoreResults.see_more }}</span>
            <img
                v-show="loading"
                :src="$assets.primary.loading"
                height="17"
                width="17"
                alt="icon"
                class="spin"
            />
            <img v-show="!loading" :src="$assets.primary.arrowDownDouble" alt="Icono ver mas" />
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BtnSeeMoreResults',
    props: {
        handler: {
            type: Function,
            default: () => false,
        },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
})
</script>

<style lang="postcss" scoped>
.button-load-more {
    @apply flex justify-center font-semibold text-site-primary;
    &_btn {
        @apply flex h-10 min-w-max items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white p-2 px-9 text-base hover:cursor-pointer;
        img {
            @apply h-4 w-4;
        }
    }
}
</style>
